
import axios from 'axios';
class TodoDataService {



    GetHost() {
        const Host = 'https://memorial.prophet.mx/api/';
        return Host;
    }


    PostGenerarAltar = async (data,closed) => {
        const url = this.GetHost() + "altar";
        const auth = {
            username: "memorialuser",
            password: "pNn5GvtpWw2x57o!sWEYp6"
        };
        

        const dataclean = {
            nombreFinado: data.NombreFinado,
            tipo: data.Tipo,
            frase: data.Frase,
            nombre:data.Nombre,
            email: data.Email,
            celular: data.Celular,
            recibeInformacion: data.RecibeInformacion,
            foto: data.Foto,
            extencion: data.Extencion
          }


        await axios
            .post(url, JSON.stringify(dataclean), {
                auth: auth,
                headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
                console.log("Exito");
                closed();
            })
            .catch((error) => {
                if (error.response) {
                    // El servidor respondió con un código de estado fuera del rango 2xx
                    console.log("Error status:", error.response.status);
                    console.log("Error data:", error.response.data);
                } else if (error.request) {
                    // La solicitud se realizó pero no se recibió respuesta
                    console.log("No response received:", error.request);
                } else {
                    // Algo pasó al configurar la solicitud
                    console.log("Error setting up the request:", error.message);
                }            });

    }

}
export default new TodoDataService();