import Home from "../Pages/Home";



const routes = 
[
    {name:'COLUMBA',path:'/',component:Home},
    // {name:'SETS FUNERARIOS',path:'/SetsFunerarios',component:SetsFunerarios},
]

export default routes