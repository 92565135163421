import React, { Component } from "react";
import TodoDataService from '../../Hooks/services'
// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';


import ImgTitulo from '../../Assets/Imagenes/Home/titulo.png';


import './Css/Styles.css';



class PlanesFuturos extends Component {
  state = {
    value: '',
    Data: [],
    Form: {
      NombreFinado: '',
      Tipo: '',
      Frase: '',
      Nombre: '',
      Email: '',
      Celular: '',
      RecibeInformacion:'',
      Foto: '',
      Extencion: ''
    },
    Error: '',
  }


  handleCheckboxChange = (event) => {
    this.setState({
      Form: {
        ...this.state.Form,
        RecibeInformacion: event.target.checked  // true si está marcado, false si no
      }
    });
  };

  handleChange = async (e) => {
    // e.persist();

    if (e.target.type === 'file') {
      if (e.target.files[0]) {
        const Archivo = e.target.files[0]
        if (Archivo.size > 3000000) {
          alert('supero el limite permitido');
          e.target.value = "";
        } else {
          const file = e.target.files[0]; // Obtener el archivo seleccionado
          if (file) {
            try {
              // Convertir el archivo en Base64
              let ImagenBase64 = await this.blobToBase64(file);
              await this.setState({
                Form: {
                  ...this.state.Form,
                  Extencion :ImagenBase64[0],
                  Foto : ImagenBase64[1],
                }
              });
              console.log('Tipo MIME:', ImagenBase64[0]); 
              console.log('Base64:', ImagenBase64[1]); 
            } catch (error) {
              console.error('Error al convertir el archivo:', error);
            }
          }
        }
      }

    }
    else {
      await this.setState({
        Form: {
          ...this.state.Form,
          [e.target.name]: e.target.value
        }
      });
    }
    console.log(this.state.Form);
  }

  blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); // Convierte el blob a base64
      reader.onloadend = () => {
        resolve(reader.result.split(',')); // Divide entre el tipo MIME y el base64
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleFileChange = async (event) => {
    const file = event.target.files[0]; // Obtener el archivo seleccionado
    if (file) {
      try {
        // Convertir el archivo en Base64
        let ImagenBase64 = await this.blobToBase64(file);
        console.log('Tipo MIME:', ImagenBase64[0]); // Muestra el tipo MIME (p. ej., "data:image/jpeg;base64")
        console.log('Base64:', ImagenBase64[1]); // Muestra los datos en base64
      } catch (error) {
        console.error('Error al convertir el archivo:', error);
      }
    }
  };


  handleChangethelepone = (e) =>{
    if(e.target.value.length < 11 )
    {
      this.handleChange(e);
    }
  }
  handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();


    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log('completa todos los campos')
    }


    if (form.checkValidity() === true) {
      console.log('completa todos los campos')
      
      TodoDataService.PostGenerarAltar(this.state.Form,()=>(window.location.reload()));
    }

  };

  render() {
    console.log(this.state.Form);

    return (
      <Box width='100%' mt='24px'>
        <Typography color='#D14081 !important' textAlign='center' variant="h1" fontSize='42px'>
          <img alt='titulo' width='75%' src={ImgTitulo} />
        </Typography>
        <Box mt='24px' sx={{ flexGrow: 1 }} >
          <Grid container spacing={2} >
            <Grid size={{ xs: 12, sm: 12, md: 2, lg: 3, xl: 3 }} >
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 8, lg: 6, xl: 6 }} sx={{ background: '#ebebd3' }}>
              <Box component='form' validated="false" onSubmit={this.handleSubmit}>
                <Container sx={{ maxWidth: { md: '450px !important', lg: '600px !important', xl: '800px !important' } }}>
                  <Typography margin="20px 0px" variant="h3" fontSize="18px" fontWeight="bold !important" color="#913b8e !important" fontFamily='Inter, sans-serif !important' className="Tipografia_base">
                    Ingresa los datos para generar tu altar
                  </Typography>

                  <Box mt="24px" >
                    <Typography className="Tipografia_base" fontSize="14.5px" variant="label" fontWeight="bold !important" >
                      Nombre de tu ser querido
                    </Typography>
                    <Typography className="Tipografia_base" ml="5px" mb="4px" variant="label" fontSize="12.25px " >
                      (obligatorio)
                    </Typography>
                    <input style={{ border: '' }} type="text" onChange={this.handleChange} name='NombreFinado' required='true' className="form-control Input" id="NombreFinado" placeholder="Nombre de tu ser querido" />
                  </Box>

                  <Box mt="24px" >
                    <Typography className="Tipografia_base" variant="label" fontSize="14.5px" fontWeight="bold !important" >
                      Selecciona el tipo de altar
                    </Typography>
                    <Typography className="Tipografia_base" ml="5px" mb="4px" variant="label" fontSize="12.5px" >
                      (obligatorio)
                    </Typography>
                    <select style={{ border: '' }} name='Tipo' onChange={this.handleChange} required='true' className="form-control" id="inGenero_finado">
                      <option value="" >Selecciona una opcion</option>
                      <option value="Altar colibríes">Altar colibríes</option>
                      <option value="Altar cempasúchil">Altar cempasúchil</option>
                      <option value="Altar Catrina">Altar Catrina</option>
                      <option value="Altar velas">Altar velas</option>
                    </select>
                  </Box>

                  <Box mt="24px" >
                    <Typography className="Tipografia_base" variant="label" fontSize="14.5px" fontWeight="bold !important">
                    Sube una fotografia donde este tu ser querido
                    </Typography>
                    <Typography className="Tipografia_base" ml="5px" mb="4px" variant="label" fontSize="12.5px" >
                      (obligatorio)
                    </Typography>
                    <input style={{ border: '' }} type="file" name='Foto' onChange={this.handleChange} required="true" className="form-control " id="Foto" placeholder="Nombre de tu ser querido" />
                  </Box>

                  <Typography className="Tipografia_base" margin="20px 0px" variant="h3" fontSize="18px" fontWeight="bold !important" fontFamily='Inter, sans-serif !important' color="#913b8e !important">
                    Indícanos a dónde enviaremos el altar
                  </Typography>

                  <Box mt="24px" >
                    <Typography className="Tipografia_base" variant="label" fontSize="14.5px" fontWeight="bold !important" >
                      Nombre
                    </Typography>
                    <Typography className="Tipografia_base" ml="5px" mb="4px" variant="label" fontSize="12.5px" >
                      (obligatorio)
                    </Typography>
                    <input style={{ border: '' }} type="text" name='Nombre' onChange={this.handleChange} required='true' className="form-control " id="Nombre" placeholder="Nombre de responsable" />
                  </Box>

                  <Box mt="24px" >
                    <Typography className="Tipografia_base" variant="label" fontSize="14.5px" fontWeight="bold !important">
                      Correo electrónico
                    </Typography>
                    <Typography className="Tipografia_base" ml="5px" mb="4px" variant="label" fontSize="12.5px">
                      (obligatorio)
                    </Typography>
                    <input style={{ border: '' }} type="email" name='Email' onChange={this.handleChange} required='true' className="form-control " id="Email" placeholder="Correo Electronico" />
                  </Box>

                  <Box mt="24px" >
                    <Typography className="Tipografia_base" variant="label" fontSize="14.5px" fontWeight="bold !important">
                      Celular
                    </Typography>
                    <Typography className="Tipografia_base" ml="5px" mb="4px" variant="label" fontSize="12.5px" >
                      (obligatorio)
                    </Typography>
                    <input style={{ border: '' }} type="number" value={this.state.Form.Celular} name='Celular' onChange={this.handleChangethelepone} required='true' className="form-control " id="Nombre de tu ser querido" placeholder="Celular" />
                  </Box>

                  

                  <Box mb="24px" padding="0% 10%" >
                    <Typography mt="10px" display="block" variant="label" fontSize="16px" className="Tipografia_base">
                      Enviaremos tu altar por WhatsApp.
                    </Typography>
                    <Typography className="Tipografia_base" display="block" mt="24px" variant="label" fontSize="16px">
                      Adicionalmente a tu altar, recibirás en tu correo un certificado por un 10% de descuento en la contratación de tu homenaje a futuro.
                    </Typography>
                  </Box>

                  <Box mb="24px" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div>
                      <Checkbox id="inAceptoInformacion_finado" checked={this.state.Form.RecibeInformacion} onChange={this.handleCheckboxChange}  required={true} sx={{ display: 'flex', alignItems: 'center', padding: '0px', color: '#913b8e !important', '&.Mui-checked': { color: '#913b8e !important' } }} className='custom-checkbox' name='Terminos' type="checkbox" id="inAceptoInformacion_finado" />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                      <h6 style={{ lineHeight: '1', fontSize: '14.5px' }} className="Tipografia_base">
                        Estoy de acuerdo en recibir información sobre "Homenajes a futuro" en mi correo o por mensaje.
                      </h6>
                    </div>

                  </Box>

                  <Box mb="24px" width='85px'>
                    <Button type='submit' variant='contained' sx={{ ':disabled': { padding: '8px 16px' }, border: 'none', textTransform: 'none', fontFamily: 'Itaca ', height: 'auto', '&:hover': { border: 'none', backgroundColor: '#a848a5', color: 'white' }, background: '#913b8e', color: 'white', width: '100%', fontWeight: 'bold', padding: '8px', borderRadius: '40px' }}>
                      <Typography fontWeight={400} fontSize='16px'>Enviar</Typography>
                    </Button>
                  </Box>

                </Container>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 2, lg: 3, xl: 3. }}>
              
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default PlanesFuturos;

