// -----------Importar Componentes de Terceros------------------
import Box from '@mui/material/Box';

// -----------Importar Componentes Personalizados------------------

import ImgMarqueina from '../../Assets/Imagenes/Home/marquesina.png';
import './Css/Styles.css';


const Marquesina = (({ }) => {

  return (
    <Box  sx={{ display: 'flex', justifyContent: 'center', width: '100%', height:'470px'}}>
        <img className='marquesina' alt='Marquesina home' src={ImgMarqueina} height='100%' width='100%'/>
    </Box>
  );
})


export default Marquesina;